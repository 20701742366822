.imageContainer {
    border: 5px solid #ffffff;
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.88);
}

@keyframes blink {
    0% {border-color: #7ac968;}
    50% {border-color: white;}
    100% {border-color: #7ac968;}
}

.blink {
    animation: blink 1s linear infinite;
}

.ant-table-fixed {
    table-layout: fixed;
}

.ant-table-tbody > tr > td {
    word-wrap: break-word;
    word-break: break-all;
}

.keyboard_button_default {
    font-size: 24px;
    height: 80px !important;
}

.keyboard_button_action {
    font-size: 30px;
    font-weight: bold;
    color: #ffffff;
    background: #7ac968 !important;
    height: 80px !important;
}

.keyboard_button_action_disabled {
    height: 80px !important;
    font-size: 30px;
    font-weight: bold;
    color: rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
    border-color: #d9d9d9;
    background: rgba(0, 0, 0, 0.04) !important;
}

.radioGroup {
    display: flex !important;
    justify-content: stretch !important;
}

.radioButton {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 52px !important;
    font-size: 24px !important;
}